import { ConstructionOutlined } from "@mui/icons-material";

export function chooseCorrespondence(a, b){
    if(a=="latin"){
        if(b=="Nko"){
            return latinNkoCorrespondence;
        }
        if(b=="Adlam"){
            return latinAdlamCorrespondence;
        }
        if(b=="Gʋlse"){
            return latinGʋlseCorrespondence;
        }
        if(b=="Nsibidi"){
            return englishNsibidiCorrespondence;
        }

    }

}
export function choosePreProcessing(a, b, textToConvert){
    console.log(a, b, textToConvert);
   
    if(a=="latin"){
        if(b=="Nko"){
            return LatinToNkoPreProcessing(textToConvert);
        }
        if(b=="Adlam"){
            return LatinToAdlamPreProcessing(textToConvert);
        }
        if(b=="Gʋlse"){
            return LatinToGʋlsePreProcessing(textToConvert);
        }
        if(b=="Nsibidi"){
            return LatinToNsibidiPreProcessing(textToConvert);
        }

    }

}
export function LatinToNkoPreProcessing(latinString){
    //Replace all 'ck' with 'k'
    latinString = latinString.replace(/ck/g, "k");
    //If the string starts with c replace it with k
    if(latinString.startsWith("c")){
        latinString = latinString.replace(/c/g, "k");
    }
    //If the string contains a c that is not the first letter, replace it with s
    latinString = latinString.replace(/c(?![a-zA-Z])/g, "s");
    //Replace all 'x' with 'ks'
    latinString = latinString.replace(/x/g, "ks");
    
    return latinString;
    
}
export function LatinToAdlamPreProcessing(latinString){
    //Replace all 'ck' with 'k'
    latinString = latinString.replace(/ck/g, "k");
    //If the string starts with c replace it with k
    if(latinString.startsWith("c")){
        latinString = latinString.replace(/c/g, "k");
    }
    //If the string contains a c that is not the first letter, replace it with s
    latinString = latinString.replace(/c(?![a-zA-Z])/g, "s");
    //Replace all 'x' with 'ks'
    latinString = latinString.replace(/x/g, "ks");
    return latinString;
    
}
export function LatinToGʋlsePreProcessing(latinString){
    //Replace all 'ck' with 'k'
    latinString = latinString.replace(/ck/g, "k");
    //If the string starts with c replace it with k
    if(latinString.startsWith("c")){
        latinString = latinString.replace(/c/g, "k");
    }
    //If the string contains a c that is not the first letter, replace it with s
    latinString = latinString.replace(/c(?![a-zA-Z])/g, "s");
    //Replace all 'x' with 'ks'
    latinString = latinString.replace(/x/g, "ks");
    //replace all qu with kw
    latinString = latinString.replace(/qu/g, "kw");
    //Replace all q with 'k'
    latinString = latinString.replace(/q/g, "k");

    return latinString;
}
export function LatinToNsibidiPreProcessing(latinString){
    //If the string does not contain a space and the string does not exist inside the correspondence table, return the empty string
    if(!latinString.includes(" ") && !(latinString in englishNsibidiCorrespondence)){
        return "";
    }
    //If the string contains a space, split the string into an array of words
    if(latinString.includes(" ")){
        let words = latinString.split(" ");
        //For each word in the array, check if it exists in the correspondence table, if it does  not remove it from the array
        for(let i = 0; i < words.length; i++){
            if(!(words[i] in englishNsibidiCorrespondence)){
                words.splice(i, 1);
                i--;
            }
        }
        //Join the array into a string
        latinString = words.join(" ");
        //Return the string
        return latinString;
    }
    
    return latinString;
}

export let latinAdlamCorrespondence = {
    "a": "𞤢",
    "b": "𞤦",
    "c": "𞤷",
    "d": "𞤣",
    "e": "𞤫",
    "f": "𞤬",
    "g": "𞤺",
    "h": "𞤸",
    "i": "𞤭",
    "j": "𞤶",
    "k": "𞤳",
    "l": "𞤤",
    "m": "𞤥",
    "n": "𞤲",
    "o": "𞤮",
    "p": "𞤨",
    "q": "",
    "r": "𞤪",
    "s": "𞤧",
    "t": "",
    "u": "𞤵",
    "v": "𞤾",
    "w": "𞤱",
    "x": "",
    "y": "𞤴",
    "z": "",
    "ɓ":"𞤩",
    "d":"𞤯",
    "ƴ":"𞤰",
    "ɠ ":"𞤹",
    "ñ": "𞤻",
    "t":"𞤼",
    "ŋ":"𞤽",
    "A":"𞤀",
    "B":"𞤄",
    "C":"𞤕",
    "D":"𞤁",
    "E":"𞤉",
    "F":"𞤊",
    "G":"𞤘",
    "H": "𞤖",
    "I": "𞤋",
    "J": "𞤔",
    "K": "𞤑",
    "L": "𞤂",
    "M": "𞤃",
    "N": "𞤐",
    "O": "𞤌",
    "P": "𞤆",
    "Q": "𞤗",
    "R": "𞤈",
    "S": "𞤅",
    "T": "𞤚",
    "U": "𞤓",
    "V": "𞤜",
    "W": "𞤏",
    "X": "𞤝",
    "Y": "𞤒",
    "Z": "𞤟",
    "0": "𞥐",
    "1": "𞥑",
    "2": "𞥒",
    "3": "𞥓",
    "4": "𞥔",
    "5": "𞥕",
    "6": "𞥖",
    "7": "𞥗",
    "8": "𞥘",
    "9": "𞥙",


}
export let latinNkoCorrespondence = {
    "a": "ߊ",
    "b": "ߓ",
    "c": "ߗ",
    "d": "ߘ",
    "e": "ߍ",
    "f": "ߝ",
    "g": "ߜ",
    "h": "ߤ",
    "i": "ߌ",
    "j": "ߖ",
    "k": "ߞ",
    "l": "ߟ",
    "m": "ߡ",
    "n": "ߒ",
    "o": "ọ",
    "p": "ߔ",
    "q": "𞤹",
    "r": "ߙ",
    "s": "ߛ",
    "t": "ߕ",
    "u": "ߎ",
    "v": "ߥ",
    "w": "ߥ",
    "x": "ߞߛ",
    "y": "ߌ",
    "z": "ߛ",
    "gb": "ߜ",
    "rr": "ߚ",
    "!": "߹",
    "?": "¿",
    ",": "߸",
    ".": "߷",
}
export let latinGʋlseCorrespondence = {
    "a": "⟋",
    "b": "ᐃ",
    "c": "⟠",
    "d": "⧨",
    "e": "⟍",
    "f": "𐒧",
    "g": "𐒨",
    "h": "ᔕ",
    "i": "",
    "j": "⊃",
    "k": "ᗒ",
    "l": "ᗕ",
    "m": "ᗑ",
    "n": "ᗐ",
    "ny":"⍋",
    "ɲ":"Ꜹ",
    "o": "□",
    "p": "↼",
    "r": "⫰",
    "s": "⊦",
    "t": "߉",
    "u": "v",
    "ũ": "⩒",
    "ʋ": "⩔",
    "v": "",
    "w": "𐌎",
    "x": "",
    "y": "ϴ",
    "z": "ᕂ",
    "ɗ":"⇀",
    "ʐ": "⊂",
    "gb": "ߜ",



}

export let englishNsibidiCorrespondence = {
    "leopard":"\u864E",
    "child":"\u5B50",
    "ancient":"\u9577",
    "tree":"\u6728",
    "forest":"\u6797",
    "tree":"\u67CF",
    "root":"\u672C",
    "king":"\u738B",
    "hall":"\u5BAB",
    "leaf":"\u8449",
    "water":"\u6C34",
    "snake":"\u86C7",
    "female":"\u5973",
    "male":"\u7537",
    "self":"\u81EA",
    "breast":"\u4E73",
    "human":"\u4EBA",
    "house":"\u5BB6",
    "four":"\u56DB",
    "hardship":"\u984C",
    "sword":"\u5263",
    "machete":"\u65AC",
    "youth":"\u82E5",
    "rod":"\u68D2",
    "train":"\u6163",
    "below":"\u5F31",
    "razor":"\u5292","place":"\u6240","assistance":"\u6551",
    "one":"\u4E00","two":"\u4E8C","three":"\u4E09","shoot":"\u683D",
    "guardianship":"\u5099","gun":"\u6A5F","sun":"\u65E5","shine":"\u5DEE",
    "illuminate":"\u7167","clean":"\u7DBA","pure":"\u9E97","oil":"\u697D",
    "search":"\u6C42","show":"\u63A2","impregnate":"\u8208","five":"\u4E94","six":"\u516D",
    "seven":"\u4E03",
    "top":"\u4E0A","edge":"\u793A",
    "levy":"\u6367","precipitation":"\u96E8",
    "day":"\u51CC","thin":"\u970E","sky":"\u4E9C",
    "hill":"\u54C0","eight":"\u516B","nine":"\u4E5D",
    "ten":"\u5341","star":"\u62FE","night":"\u66D6",
    "mat":"\u60AA","disturbance":"\u63E1","wealth":"\u5727",
    "leg":"\u6271","movement":"\u5B9B","animal":"\u5D50","swell":"\u5B89",
    "it":"\u6848","steal":"\u6697","people":"\u4EE5","person":"\u8863",
    "hook":"\u4F4D","T;RINITI":"\u571F","join":"\u63A5","lover":"\u59D4",
    "glue":"\u5A01","journey":"\u70BA","way":"\u754F","split":"\u80C3",
    "pregnancy":"\u598A","message":"\u8A17","big":"\u5927","heart":"\u5FC3",
    "eye":"\u76EE","see":"\u898B","pillar":"\u5C09","width":"\u7570",
    "camwood":"\u79FB","key":"\u840E","light":"\u5049","glitter":"\u6905",
    "spider":"\u5F59","ladder":"\u610F","bridge":"\u9055","trunk":"\u7DAD",
    "month":"\u6170","year":"\u907A","tide":"\u7DEF","over-clouding":"\u57DF",
    "halo":"\u80B2","tortoise":"\u58F1","nsibiri":"\u5B57",
    "bar":"\u9038","cage":"\u8328","love":"\u611B","super":"\u828B",
    "mouth":"\u53E3","front":"\u5370","fall":"\u54E1","public":"\u9662",
    "\u0173k\xE0r\xE0":"\u6DEB","south":"\u9670","east":"\u98F2",
    "west":"\u96A0","cut":"\u97FB","begin":"\u53F3","come":"\u5B87",
    "also":"\u7FBD","slave":"\u5504","and":"\u755D","or":"\u6D66",
    "Igbo":"\u904B","but":"\u96F2","if":"\u6C38","because":"\u6CF3",
    "that":"\u82F1","even":"\u6620","now":"\u6804","in":"\u55B6",
    "country":"\u8A60","talkative":"\u5F71","speak":"\u92ED",
    "embrace":"\u885B","rhythm":"\u6613","join":"\u75AB",
    "nut":"\u76CA","greet":"\u6DB2","follow":"\u99C5","chalk":"\u60A6",
    "black":"\u8D8A","up":"\u8B01","husband":"\u95B2","wife":"\u5186",
    "exist":"\u5EF6","anchor":"\u6CBF","currency":"\u91D1","zero":"\u708E",
    "way":"\u6028","necessary":"\u5BB4","happiness":"\u5A9B","war":"\u63F4",
    "stone":"\u5712","beauty":"\u7159","food":"\u733F","fire":"\u9060","essence":"\u9B42",
    "welcome":"\u925B","group":"\u5869","time":"\u6F14","breeze":"\u7E01",
    "TRINITI":"\u8276","buy":"\u51F9","feather":"\u592E","entrance":"\u5FDC",
    "end":"\u5F80","plantain":"\u62BC","door":"\u65FA","warrior":"\u6B27","question":
    "\u6BB4","nation":"\u685C","no":"\u7FC1","work":"\u5965","strong":"\u6A2A","drum":
    "\u5CA1","Ekwensu":"\u5C4B","trick":"\u5104","marriage":"\u61B6","deceit":"\u81C6",
    "mind":"\u865E","tie":"\u4E59","corpse":"\u4FFA","fear":"\u5378","death":"\u97F3",
    "kill":"\u6069","mother":"\u6E29","father":"\u7A4F","please":"\u4E0B","ugly":"\u5316",
    "sojourn":"\u706B","Ala":"\u52A0","forbidden":"\u53EF","courtesan":"\u4EEE",
    "rain":"\u4F55","me":"\u82B1","you":"\u4F73","quarrel":"\u4FA1","strength":"\u679C",
    "sweeten":"\u6CB3","cold":"\u82DB","part":"\u79D1","money":"\u67B6","race":"\u590F",
    "smoke":"\u8377","say":"\u83EF","we":"\u83D3","oil":"\u8CA8","sick":"\u6E26",
    "spring":"\u904E","copulate":"\u5AC1","back":"\u6687","slip":"\u798D",
    "queen":"\u9774","snatch":"\u5BE1","link":"\u6B4C","rejoice":"\u7B87",
    "hand":"\u7A3C","fist":"\u8AB2","wrist":"\u868A","dwarf":"\u7259",
    "mortar":"\u74E6","bee":"\u6211","fruit":"\u753B","leprosy":"\u82BD",
    "cow":"\u8CC0","drum":"\u7070","egg":"\u56DE","comb":"\u4F1A",
    "thought":"\u5FEB","child":"\u6212","girl":"\u6539","in-law":"\u602A",
    "inside":"\u62D0","bury":"\u6094","divinity":"\u6D77","that":"\u754C",
    "tradition":"\u7686","again":"\u68B0","ask":"\u7D75","earth":"\u958B",
    "leave":"\u968E","touch":"\u584A","infertility":"\u6977","time":"\u89E3",
    "time":"\u6F70","do":"\u58CA","rhythm":"\u61D0","flower":"\u8AE7",
    "enter":"\u8C9D","of":"\u5916","location":"\u52BE","protect":"\u5BB3",
    "think":"\u5D16","eat":"\u6DAF","fish":"\u8857","easy":"\u6168",
    "teach":"\u84CB","cry":"\u8A72","eat":"\u6982","tooth":"\u9AB8",
    "face":"\u57A3","excrete":"\u67FF","take":"\u5404","give":"\u89D2",
    "hair":"\u62E1","side":"\u9769","birth":"\u683C","twist":"\u6838",
    "bronze":"\u6BBB","gold":"\u90ED","well":"\u899A","vegetable":"\u8F03",
    "inflict":"\u9694","chair":"\u95A3","toad":"\u78BA","bag":"\u7372",
    "womb":"\u5687","lift":"\u7A6B","precede":"\u5B66","bless":"\u5CB3",
    "descent":"\u984D","first":"\u984E","bread":"\u639B","refuse":"\u6F5F",
    "mature":"\u62EC","goat":"\u6D3B","persevere":"\u559D","son":"\u6E07",
    "daughter":"\u5272","king":"\u845B","what":"\u6ED1","street":"\u8910",
    "pass":"\u8F44","climb":"\u4E14","movement":"\u682A","trample":"\u91DC",
    "sleep":"\u938C","mistake":"\u5208","cry":"\u5E72","debt":"\u520A",
    "calabash":"\u7518","hurt":"\u6C57","yellow":"\u7F36","marry":"\u5B8C",
    "write":"\u809D","bone":"\u5B98","sleep":"\u51A0","medicine":"\u5DFB",
    "grow":"\u770B","gossip":"\u9665","cat":"\u4E7E","today":"\u52D8",
    "tomorrow":"\u60A3","woman":"\u8CAB","space":"\u5BD2","butterfly":"\u559A",
    "fill":"\u582A","embark":"\u63DB","copy":"\u6562","from":"\u68FA","bitter":
    "\u6B3E","commitment":"\u9593","lay":"\u9591","new":"\u65B0","proverb":"\u52E7",
    "jump":"\u5BDB","visitor":"\u5E79","count":"\u611F","comet":"\u6F22",
    "thing":"\u6B20","elephant":"\u95A2","horse":"\u7DE9","name":"\u61BE",
    "order":"\u9084","pillar":"\u9928","surpass":"\u74B0","head":"\u7C21",
    "concern":"\u89B3","like":"\u97D3","still":"\u8266","know":"\u4E38",
    "elephantiasis":"\u542B","cloth":"\u5E03","weave":"\u5CB8","clothing":"\u5CA9",
    "banner":"\u73A9","want":"\u8981","pot":"\u773C","body":"\u9811","blood":"\u8A71",
    "look":"\u6D6A","what":"\u5ECA","mister":"\u697C","dusk":"\u6F0F","die":"\u7C60",
    "fear":"\u9332","ocean":"\u9E93","middle":"\u8AD6","protest":"\u548C","anger":"\u9854",
    "you":"\u9858","name":"\u540D","albino":"\u4F01","leopard":"\u4F0E","rat":"\u9F20",
    "live":"\u5241","poison":"\u5371","leave":"\u673A","sound":"\u6C17","stuff":"\u5C90",
    "enter":"\u5E0C","master":"\u5FCC","stand":"\u6C7D","firewood":"\u5947","rat":"\u7948",
    "shield":"\u5B63","shout":"\u7D00","hunting":"\u8ECC","wear":"\u65E2","Ikenga":"\u8A18",
    "borrow":"\u8D77","soap":"\u98E2","ocean":"\u9B3C","stay":"\u5E30","clear":"\u57FA",
    "fight":"\u5BC4","ear":"\u898F","resemble":"\u4E80","pox":"\u559C","conference":"\u5E7E",
    "tell":"\u63EE","horns":"\u671F","palm":"\u68CB","yes":"\u8CB4","brain":"\u68C4",
    "yam":"\u6BC0","noise":"\u65D7","describe":"\u5668","divinity":"\u757F","lightening":
    "\u8F1D","crab":"\u9A0E","trade":"\u6280","market":"\u5B9C","trader":"\u507D",
    "vessel":"\u6B3A","parrot":"\u7FA9","loom":"\u7591","hunger":"\u5100",
    "tongue":"\u622F","hook":"\u64EC","stick":"\u72A0","have":"\u8B70",
    "awn":"\u83CA","arrogance":"\u5409","language":"\u55AB",
    "speak":"\u8A70","saliva":"\u5374","carry":"\u5BA2",
    "cheek":"\u5F13","children":"\u4F11","small":"\u7A76",
    "footprint":"\u6CE3","pull":"\u6025","plate":"\u5438",
    "iron":"\u7D1A","python":"\u4E45","staff":"\u7CFE",
    "ash":"\u5BAE",
"how":"\u8650",
"luck":"\u7403",
"forest":"\u7D66",
"look":"\u55C5",
"bite":"\u81FC",
"lead":"\u53CA",
"endure":"\u811A",
"attempt":"\u4E18",
"past":"\u725B",
"peace":"\u53BB",
"garden":"\u7AAE",
"boundary":"\u9006",
"edge":"\u5DE8",
"bundle":"\u5C45",
"limb":"\u62D2",
"lick":"\u65E7",
"ash":"\u62E0",
"lover":"\u6319",
"tube":"\u865A",
"entice":"\u8A31",
"is":"\u673D",
"isn\u2019t":"\u8DDD",
"bend":"\u9B5A",
"archery":"\u5FA1",
"them":"\u51F6",
"act":"\u6F01",
"weed":"\u5171",
"voice":"\u72C2",
"different":"\u4EAC",
"TRINITI":"\u4EAB",
"genealogy":"\u7CFB",
"send":"\u9001",
"action":"\u52D5",
"male":"\u5974",
"weight":"\u91CD",
"ants":"\u87FB",
"wait":"\u7B49",
"glass":"\u73BB",
"listen":"\u631F",
"strike":"\u72ED",
"pour":"\u6050",
"hero":"\u606D",
"super":"\u80F8",
"yesterday":"\u8105",
"finish":"\u5F37",
"hide":"\u6559",
"be":"\u90F7",
"shell":"\u5883",
"laziness":"\u6A4B",
"pepper":"\u77EF",
"lose":"\u93E1",
"upset":"\u7AF6",
"anger":"\u97FF",
"slow":"\u9A5A",
"agree":"\u4EF0",
"tripod":"\u6681",
"favour":"\u696D",
"thorn":"\u51DD",
"affliction":"\u66F2",
"dark":"\u5C40",
"dry":"\u6975",
"lock":"\u7389",
"ache":"\u5DFE",
"against":"\u65A4",
"fly":"\u5747",
"deity":"\u8FD1",
"sheep":"\u83CC",
"chain":"\u52E4",
"sink":"\u7434",
"string":"\u7B4B",
"tell":"\u50C5",
"push":"\u7981",
"swear":"\u7DCA",
"pick":"\u9326",
"hang":"\u8B39",
"secure":"\u895F",
"that":"\u541F",
"sorcery":"\u9280",
"worry":"\u533A",
"madness":"\u53E5",
"wrestling":"\u82E6",
"gong":"\u99C6",
"share":"\u5177",
"cure":"\u60E7",
"close":"\u611A",
"divinity":"\u7A7A",
"open":"\u5076",
"steer":"\u9047",
"stand":"\u9685",
"destiny":"\u4E32",
"heap":"\u5C48",
"small":"\u6398",
"save":"\u7A9F",
"tiny":"\u718A",
"ablaze":"\u7E70",
"cook":"\u541B",
"tail":"\u8A13",
"ivory":"\u52F2",
"pool":"\u85AB",
"clump":"\u8ECD",
"lion":"\u90E1",
"remain":"\u7FA4",
"pinch":"\u5144",
"survive":"\u5211",
"bond":"\u5F62",
"support":"\u5F84",
"praise":"\u830E",
"periodically":"\u4FC2",
"spread":"\u5951",
"date":"\u8A08",
"dawn":"\u6075",
"will":"\u5553",
"elderly":"\u63B2",
"orphan":"\u6E13",
"grudge":"\u7D4C",
"sing":"\u86CD",
"ape":"\u656C",
"leadership":"\u6534",
"another":"\u666F",
"drink":"\u8EFD",
"plantation":"\u50BE",
"shrub":"\u643A",
"these":"\u7D99",
"murder":"\u8A63",
"horizon":"\u6176",
"stretch":"\u61AC",
"tree":"\u7A3D",
"trap":"\u61A9",
"cassava":"\u82B8",
"wood":"\u8FCE",
"leak":"\u9D8F",
"hedge":"\u9BE8",
"excreta":"\u9699",
"fisher":"\u5287",
"possess":"\u79BE",
"it":"\u6483",
"it":"\u6FC0",
"follow":"\u8A00",
"calm":"\u8B66",
"adultery":"\u6841",
"imitation":"\u7A74",
"tendon":"\u8840",
"fast":"\u6C7A",
"lift":"\u7D50",
"make":"\u5091",
"little":"\u6F54",
"throw":"\u4EF6",
"carry":"\u5238",
"measure":"\u80A9",
"opening":"\u5EFA",
"air":"\u7814",
"mirror":"\u770C",
"twin":"\u5039",
"press":"\u517C",
"wrap":"\u62F3",
"leak":"\u8ED2",
"announce":"\u5065",
"larynx":"\u967A",
"neck":"\u570F",
"cough":"\u5805",
"breadfruit":"\u691C",
"island":"\u5ACC",
"lantern":"\u732E",
"coral":"\u7D79",
"maiden":"\u9063",
"jewel":"\u6A29",
"bamboo":"\u61B2",
"horn":"\u8CE2",
"box":"\u8B19",
"fruit":"\u9375",
"long":"\u7E6D",
"naught":"\u9855",
"emptiness":"\u9A13",
"lizard":"\u61F8",
"whip":"\u5E7B",
"flog":"\u7384",
"stick":"\u5F26",
"alcohol":"\u9650",
"produce":"\u539F",
"tight":"\u8237",
"pluck":"\u6E1B",
"square":"\u6E90",
"town":"\u53B3",
"stop":"\u5DF1",
"week":"\u6238",
"eagle":"\u53E4",
"colour":"\u547C",
"model":"\u56FA",
"bend":"\u80A1",
"dog":"\u5B64",
"bark":"\u5F27",
"open":"\u6545",
"fry":"\u67AF",
"easy":"\u500B",
"separate":"\u5EAB",
"energy":"\u6E56",
"distance":"\u96C7",
"bad":"\u8A87",
"day":"\u9F13",
"enmity":"\u932E",
"divinity":"\u9867",
"run":"\u4E92",
"buttocks":"\u5449",
"waist":"\u5F8C",
"fat":"\u5A2F",
"strike":"\u609F",
"change":"\u7881",
"sorry":"\u8A9E",
"prevent":"\u8AA4",
"shade":"\u8B77",
"answer":"\u516C",
"sweep":"\u52FE",
"vertical":"\u5B54",
"proper":"\u529F",
"hundred":"\u5DE7",
"thousand":"\u5E83",
"early":"\u7532",
"soil":"\u4EA4",
"guess":"\u5149",
"straighten":"\u5411",
"track":"\u540E",
"line":"\u597D",
"yield":"\u6C5F",
"reveal":"\u8003",
"cave":"\u884C",
"hollow":"\u5751",
"slim":"\u5B5D",
"vegetation":"\u6297",
"grass":"\u653B",
"wilderness":"\u66F4",
"laugh":"\u52B9",
"shellfish":"\u5E78",
"circle":"\u62D8",
"salt":"\u80AF",
"valley":"\u4FAF",
"scoop":"\u539A",
"common":"\u6052",
"silence":"\u6D2A",
"white":"\u7687",
"outdoors":"\u7D05",
"same":"\u8352",
"poverty":"\u90CA",
"peer":"\u9999",
"pebble":"\u5019",
"sound":"\u6821",
"borrow":"\u8015",
"lower":"\u822A",
"confess":"\u8CA2",
"cross":"\u964D",
"mix":"\u9AD8",
"shape":"\u5EB7",
"number":"\u63A7",
"roll":"\u6897",
"learn":"\u9EC4",
"rest":"\u5589",
"mist":"\u614C",
"festival":"\u6E2F",
"change":"\u786C",
"overtake":"\u7D5E",
"mature":"\u9805",
"crossing":"\u6E9D",
"near":"\u9271",
"colour":"\u69CB",
"attribute":"\u7DB1",
"decide":"\u9175",
"rat":"\u7A3F",
"million":"\u8861",
"mosquito":"\u92FC",
"floor":"\u8B1B",
"sell":"\u8CFC",
"noon":"\u4E5E",
"evening":"\u53F7",
"age-set":"\u5408",
"smell":"\u62F7",
"restore":"\u525B",
"hear":"\u50B2",
"keep":"\u8C6A",
"lineage":"\u514B",
"slope":"\u544A",
"turn":"\u8C37",
"paddle":"\u523B",
"swamp":"\u56FD",
"fame":"\u9ED2",
"overseas":"\u7A40",
"cloudy":"\u9177",
"tan":"\u7344",
"kind":"\u9AA8",
"morning":"\u99D2",
"friend":"\u8FBC",
"expiration":"\u9803",
"stone":"\u4ECA",
"soft":"\u56F0",
"cloud":"\u6606",
"fan":"\u6068",
"vulture":"\u6839",
"grasshopper":"\u5A5A",
"spade":"\u6DF7",
"pluck":"\u75D5",
"joke":"\u7D3A",
"walk":"\u58BE",
"height":"\u61C7",
"smash":"\u5DE6",
"thicken":"\u4F50",
"ripen":"\u6C99",
"distant":"\u67FB",
"illustrate":"\u7802",
"profit":"\u5506",
"cotton":"\u8A50",
"banana":"\u9396",
"tree":"\u5EA7",
"equalise":"\u632B",
"suffering":"\u624D",
"lawed":"\u518D",
"keep":"\u707D",
"sign":"\u59BB",
"exit":"\u91C7",
"tick":"\u7815",
"grass":"\u5BB0",
"grass":"\u5F69",
"dizzy":"\u63A1",
"press":"\u6E08",
"snore":"\u796D",
"flavour":"\u658E",
"chill":"\u7D30",
"waterfall":"\u83DC",
"wonder":"\u6700",
"various":"\u88C1",
"wool":"\u50B5",
"mimic":"\u50AC",
"rice":"\u585E",
"borrow":"\u6B73",
"weakling":"\u8F09",
"mix":"\u969B",
"foolish":"\u57FC",
"defect":"\u5728",
"row":"\u6750",
"line":"\u5264",
"tiredness":"\u8CA1",
"hindrance":"\u7F6A",
"position":"\u5D0E",
"family":"\u4F5C",
"bed":"\u524A",
"cocoyam":"\u6628",
"design":"\u67F5",
"lie":"\u7D22",
"scatter":"\u7B56",
"pledge":"\u9162",
"shoulder":"\u643E",
"foreleg":"\u932F",
"stale":"\u54B2",
"net":"\u518A",
"waterside":"\u672D",
"tribe":"\u5237",
"darken":"\u5239",
"announce":"\u62F6",
"sandbank":"\u6BBA",
"shoe":"\u5BDF",
"chicken":"\u64AE",
"harmattan":"\u64E6",
"wasteland":"\u96D1",
"witch":"\u76BF",
"wizard":"\u5C71",
"carpentry":"\u53C2",
"drawing":"\u685F",
"pull":"\u8695",
"seduce":"\u60E8",
"shadow":"\u7523",
"uproot":"\u5098",
"temptation":"\u6563",
"season":"\u7B97",
"snip":"\u9178",
"climb":"\u8CDB",
"standing":"\u6B8B",
"tool":"\u66AB",
"health":"\u58EB",
"waterside":"\u652F",
"borrowing":"\u6B62",
"knife":"\u6C0F",
"mud":"\u4ED5",
"farm":"\u53F2",
"light":"\u5E02",
"heat":"\u65E8",
"spoil":"\u6B7B",
"wash":"\u7CF8",
"fishing":"\u81F3",
"play":"\u4F3A",
"laugh":"\u5FD7",
"drum":"\u79C1",
"collect":"\u4F7F",
"wall":"\u523A",
"wall":"\u59CB",
"jail":"\u59C9",
"creation":"\u679D",
"return":"\u7949",
"good":"\u80A2",
"goose":"\u59FF",
"nest":"\u601D",
"locality":"\u6307",
"sing":"\u65BD",
"wateryam":"\u5E2B",
"skin":"\u6063",
"pus":"\u7D19",
"bullet":"\u8102",
"waterleaf":"\u8996",
"hurt":"\u7D2B",
"abyss":"\u8A5E",
"dry":"\u6B6F",
"slip":"\u55E3",
"absorb":"\u8A66",
"sprout":"\u8A69",
"all":"\u8CC7",
"tie":"\u98FC",
"forceps":"\u8A8C",
"spoon":"\u96CC",
"fork":"\u646F",
"scarcity":"\u8CDC",
"insult":"\u8AEE",
"distance":"\u5BFA",
"mat":"\u6B21",
"he-goat":"\u8033",
"mortar":"\u4F3C",
"particle":"\u5150",
"shortness":"\u4E8B",
"section":"\u4F8D",
"steep":"\u6CBB",
"society":"\u6301",
"crossbow":"\u6642",
"twist":"\u6ECB",
"season":"\u6148",
"reveal":"\u8F9E",
"up":"\u78C1",
"support":"\u990C",
"wipe":"\u74BD",
"worship":"\u9E7F",
"adornment":"\u5F0F",
"hello":"\u8B58",
"stretch":"\u8EF8",
"establish":"\u53D7",
"rim":"\u6388",
"wake":"\u9700",
"rub":"\u5112",
"report":"\u6A39",
"stab":"\u53CE",
"compress":"\u56DA",
"grunt":"\u5DDE",
"submerge":"\u821F",
"froth":"\u79C0",
"pound":"\u5468",
"weigh":"\u5B97",
"buttocks":"\u79CB",
"snot":"\u81ED",
"jaw":"\u4FEE",
"stomach":"\u8896",
"throw":"\u7D42",
"peacock":"\u7F9E",
"volume":"\u7FD2",
"shortness":"\u9031",
"anthill":"\u5C31",
"stilt":"\u8846",
"pole":"\u96C6",
"can":"\u6101",
"rubbish":"\u916C",
"move":"\u919C",
"stammer":"\u8E74",
"vomit":"\u8972",
"red":"\u5145",
"space":"\u4F4F",
"corner":"\u67D4",
"quarters":"\u5F93",
"compound":"\u6E0B",
"wall":"\u9283",
"island":"\u7363",
"wet":"\u7E26",
"sand":"\u53D4",
"monkey":"\u795D",
"forgive":"\u5BBF",
"store":"\u6DD1",
"seat":"\u7C9B",
"heifer":"\u7E2E",
"wound":"\u587E",
"bottle":"\u719F",
"fish":"\u51FA",
"worthless":"\u8FF0",
"foolishness":"\u8853",
"foolish":"\u4FCA",
"crossroads":"\u6625",
"basket":"\u77AC",
"door":"\u65EC",
"husk":"\u5DE1",
"bead":"\u51C6",
"nuts":"\u6B89",
"industry":"\u7D14",
"choose":"\u5FAA",
"mango":"\u9806",
"thunder":"\u6E96",
"quarters":"\u6F64",
"woman":"\u9075",
"cricket":"\u51E6",
"egg":"\u521D",
"hour":"\u66F8",
"excuse":"\u5EB6",
"secret":"\u6691",
"mermaid":"\u7F72",
"rendezvous":"\u7DD2",
"knife":"\u8AF8",
"tie":"\u5982",
"thread":"\u52A9",
"desire":"\u5E8F",
"swamp":"\u53D9",
"show":"\u5F90",
"trousers":"\u9664",
"cassava":"\u5C0F",
"knot":"\u5347",
"rust":"\u5C11",
"adjudicate":"\u5320",
"hoe":"\u5E8A",
"barn":"\u6284",
"disappear":"\u8096",
"sickly":"\u5C1A",
"coil":"\u62DB",
"dig":"\u627F",
"swim":"\u6607",
"rattle":"\u677E",
"tap":"\u6CBC",
"hole":"\u662D",
"chalk":"\u5BB5",
"cowry":"\u5C06",
"grind":"\u6D88",
"lung":"\u75C7",
"pluck":"\u7965",
"trunk":"\u79F0",
"tunic":"\u7B11",
"hippo":"\u5531",
"thigh":"\u5546",
"size":"\u6E09",
"loincloth":"\u7AE0",
"history":"\u7D39",
"story":"\u8A1F",
"lip":"\u52DD",
"dream":"\u638C",
"vibrate":"\u6676",
"millipede":"\u713C",
"difficult":"\u7126",
"complaint":"\u785D",
"dispute":"\u7CA7",
"river":"\u8A54",
"hook":"\u8A3C",
"transform":"\u8C61",
"much":"\u50B7",
"child":"\u5968",
"charcoal":"\u8A73",
"blow":"\u5F70",
"avoid":"\u969C",
"cockroach":"\u61A7",
"cock":"\u885D",
"ball":"\u8CDE",
"rainbow":"\u511F",
"spherical":"\u7901",
"slimy":"\u9418",
"consider":"\u4E08",
"crawl":"\u5197",
"wing":"\u6761",
"pigeon":"\u72B6",
"leave":"\u4E57",
"service":"\u57CE",
"blue":"\u6D44",
"only":"\u5270",
"wisdom":"\u5E38",
"direct":"\u60C5",
"competition":"\u5834",
"wax":"\u7573",
"push":"\u84B8",
"plant":"\u7E04",
"round":"\u58CC",
"devotee":"\u5B22",
"knee":"\u9320",
"sized":"\u8B72",
"distort":"\u91B8",
"test":"\u8272",
"pulp":"\u62ED",
"pool":"\u98DF",
"maturity":"\u690D",
"observe":"\u6B96",
"breathe":"\u98FE",
"blow":"\u89E6",
"meat":"\u5631",
"shore":"\u7E54",
"darken":"\u8077",
"blindness":"\u8FB1",
"blur":"\u5C3B",
"shyness":"\u7533",
"change":"\u4F38",
"also":"\u81E3",
"vein":"\u82AF",
"grip":"\u8EAB",
"table":"\u8F9B",
"shake":"\u4FB5",
"mould":"\u4FE1",
"rot":"\u6D25",
"melt":"\u795E",
"ofo":"\u5507",
"scorch":"\u5A20",
"listen":"\u632F",
"odour":"\u6D78",
"successful":"\u771F",
"sacrifice":"\u91DD",
"involvement":"\u6DF1",
"aura":"\u7D33",
"tar":"\u9032",
"target":"\u68EE",
"heel":"\u8A3A",
"sharpness":"\u5BDD",
"room":"\u614E",
"peel":"\u5BE9",
"gutter":"\u9707",
"lake":"\u85AA",
"desert":"\u89AA",
"courtyard":"\u5203",
"pond":"\u4EC1",
"reason":"\u5C3D",
"transport":"\u8FC5",
"over":"\u751A",
"angle":"\u9663",
"meat":"\u5C0B",
"witness":"\u814E",
"expand":"\u9808",
"swell":"\u56F3",
"mark":"\u5439",
"compress":"\u5782",
"heal":"\u708A",
"chat":"\u5E25",
"collapse":"\u7C8B",
"hurt":"\u8870",
"communicate":"\u63A8",
"radiate":"\u9154",
"rub":"\u9042",
"flow":"\u7761",
"roast":"\u7A42",
"shiver":"\u968F",
"lap":"\u9AC4",
"window":"\u67A2",
"goodness":"\u5D07",
"float":"\u6570",
"draw":"\u636E",
"tickle":"\u6749",
"peel":"\u88FE",
"nail":"\u5BF8",
"mask":"\u702C",
"arena":"\u662F",
"clod":"\u4E95",
"beat":"\u6B63",
"nut":"\u6210",
"coagulate":"\u897F",
"nail":"\u58F0",
"clay":"\u5236",
"mould":"\u59D3",
"me":"\u5F81",
"grasp":"\u6027",
"fell":"\u9752",
"chin":"\u6589",
"coconut":"\u653F",
"manner":"\u661F",
"namesake":"\u7272",
"platform":"\u7701",
"endurance":"\u51C4",
"bachelor":"\u901D",
"summon":"\u6E05",
"container":"\u76DB",
"heat":"\u5A7F",
"cap":"\u6674",
"close":"\u52E2",
"mantis":"\u8056",
"heap":"\u8AA0",
"theivery":"\u7CBE",
"twenty":"\u88FD",
"hundred":"\u9759",
"fibre":"\u8ACB",
"hit":"\u6574",
"kidnap":"\u9192",
"senior":"\u7A0E",
"establish":"\u5915",
"loosen":"\u65A5",
"praise":"\u77F3",
"extol":"\u8D64",
"plant":"\u6614",
"erect":"\u6790",
"order":"\u5E2D",
"breathe":"\u810A",
"fling":"\u96BB",
"pattern":"\u60DC",
"measure":"\u621A",
"claw":"\u8CAC",
"drizzle":"\u8DE1",
"deceive":"\u7A4D",
"work":"\u7E3E",
"pot":"\u7C4D",
"times":"\u5207",
"fold":"\u6298",
"fog":"\u62D9",
"round":"\u7A83",
"container":"\u8A2D",
"vapour":"\u6442",
"ditch":"\u7BC0",
"tobacco":"\u8AAC",
"forest":"\u820C",
"calabash":"\u7D76",
"beans":"\u4ED9",
"depression":"\u5C02",
"disgust":"\u6CC9",
"raffia":"\u6D45",
"dryness":"\u6D17",
"testicle":"\u67D3",
"forehead":"\u6247",
"throat":"\u6813",
"money":"\u65CB",
"bribe":"\u8239",
"fly-whisk":"\u6226",
"storehouse":"\u714E",
"widower":"\u7FA8",
"assault":"\u8A6E",
"riverain":"\u8DF5",
"shoot":"\u7B8B",
"sharpen":"\u92AD",
"shine":"\u6F5C",
"taproot":"\u7DDA",
"all":"\u9077",
"all":"\u9078",
"sieve":"\u85A6",
"elbow":"\u7E4A",
"consider":"\u9BAE",
"section":"\u5168",
"bore":"\u524D",
"flies":"\u5584",
"field":"\u7136",
"pay":"\u7985",
"swagger":"\u6F38",
"knead":"\u81B3",
"comb":"\u7E55",
"statement":"\u72D9",
"burn":"\u7D44",
"dip":"\u53CC",
"pence":"\u8358",
"evaporate":"\u8349",
"affix":"\u5009",
"share":"\u635C",
"sprinkle":"\u633F",
"menses":"\u6851",
"verandah":"\u5DE3",
"swallow":"\u6383",
"extinguish":"\u66F9",
"\u6821\u4E08\u6B4C":"\u66FD",
"placenta":"\u50E7",
"homestead":"\u60F3",
"regular":"\u5C64",
"weave":"\u7DCF",
"add":"\u906D",
"step":"\u69FD",
"instrument":"\u8E2A",
"music":"\u64CD",
"tortoise":"\u71E5",
"sweat":"\u971C",
"adult":"\u9A12",
"periodically":"\u85FB",
"warrior":"\u9020",
"semen":"\u50CF",
"prepare":"\u5897",
"avenge":"\u963B",
"village":"\u758E",
"sneeze":"\u55AA",
"breath":"\u618E",
"fool":"\u5B58",
"trick":"\u905C",
"outskirts":"\u4EE3",
"to":"\u53F0",
"assign":"\u629E",
"begin":"\u6FEF",
"gong":"\u8131",
"warrant":"\u4E39",
"porcupine":"\u935B",
"hernia":"\u5F3E",
"tree":"\u5730",
"cow":"\u6065",
"hen":"\u4EF2",
"greeting":"\u4E01",
"pelt":"\u5E33",
"parasite":"\u5F35",
"potato":"\u5F6B",
"hall":"\u773A",
"seagull":"\u9802",
"season":"\u671D",
"yam":"\u6311",
"hair":"\u755C",
"blame":"\u7A1A",
"apparition":"\u5606",
"vulva":"\u70AD",
"middle":"\u7956",
"scrape":"\u8A34",
"sheath":"\u75E9",
"fillet":"\u8535",
"trench":"\u6751",
"cup":"\u4ED6",
"palm":"\u6234",
"bundle":"\u7B2C",
"spear":"\u6CA2",
"please":"\u8AFE",
"grove":"\u596A",
"he-goat":"\u65E6",
"land":"\u56E3",
"conduct":"\u6696",
"spleen":"\u6C60",
"purpose":"\u81F4",
"rudder":"\u866B",
"ululation":"\u5F14",
"sanza":"\u5E81",
"xylophone":"\u5146",
"strings":"\u5AE1",
"gong":"\u91E3",
"revenge":"\u9CE5",
"many":"\u8CBC",
"steer":"\u753A",
"cloth":"\u9010",
"poultry":"\u7F6E",
"breed":"\u7AEF",
"pelvis":"\u80C6",
"pineapple":"\u79DF",
"hole":"\u5851",
"migraine":"\u846C",
"divination":"\u8D08",
"gill":"\u5B6B",
"loom":"\u591A",
"needle":"\u614B",
"shrimp":"\u6EDD",
"huge":"\u5353",
"crook":"\u6FC1",
"harpoon":"\u68DA",
"clitoris":"\u62C5",
"hearth":"\u6BB5",
"termite":"\u8AC7",
"console":"\u77E5",
"rattle":"\u9045",
"mat":"\u6C96",
"bearing":"\u8877",
"potion":"\u914E",
"kernel":"\u92F3",
"kidney":"\u7A92",
"peanut":"\u79E9",
"dispute":"\u99D0",
"into":"\u8457",
"comb":"\u8CAF",
"lunch":"\u84C4",
"perch":"\u7DFB",
"chameleon":"\u7DBB",
"respect":"\u6DE1",
"shin":"\u7D20",
"thread":"\u9061",
"silence":"\u5275",
"evening":"\u81D3",
"throb":"\u5C0A",
"pneumonia":"\u6C70",
"wit":"\u6EDE",
"urine":"\u5B85",
"mute":"\u62D3",
"nearby":"\u4F46",
"dove":"\u8AB0",
"belt":"\u5358",
"beads":"\u65AD",
"plant":"\u58C7",
"chamber":"\u5024",
"platform":"\u75F4",
"soak":"\u5B99",
"coward":"\u67F1",
"probe":"\u663C",
"wave":"\u6CE8",
"granary":"\u8336",
"dew":"\u7740",
"effort":"\u62BD",
"foreskin":"\u5FE0",
"forest":"\u4E2D",
"liver":"\u7BC9",
"manners":"\u7AF9",
"usefulness":"\u8A95",
"mourning":"\u77ED",
"overnight":"\u63AA",
"gift":"\u790E",
"spirit-double":"\u7A93",
"tribute":"\u5373",
"dawn":"\u640D",
"smithy":"\u6253",
"lagoon":"\u59A5",
"hospitality":"\u553E",
"mortar":"\u5815",
"leisure":"\u60F0",
"myth":"\u99C4",
"fowl":"\u592A",
"all":"\u5BFE",
"mildew":"\u4F53",
"haze":"\u8010",
"sea":"\u5F85",
"achieve":"\u6020",
"omen":"\u80CE",
"snail":"\u9000",
"lily":"\u5E2F",
"ladle":"\u6CF0",
"knot":"\u5806",
"woods":"\u888B",
"jealousy":"\u902E",
"raft":"\u66FF",
"rafter":"\u8CB8",
"latex":"\u968A",
"leech":"\u7387",
"rabbit":"\u5352",
"cage":"\u7C97",
"hook":"\u58EE",
"package":"\u723D",
"fine":"\u675F",
"spy":"\u8DB3",
"silk":"\u4FC3",
"pray":"\u5247",
"calm":"\u606F",
"reed":"\u6349",
"sway":"\u901F",
"droop":"\u5074",
"hire":"\u6E2C",
"appoint":"\u4FD7",
"appoint":"\u65CF",
"uncle":"\u5C5E",
"aunt":"\u8CCA",
"innocence":"\u7D9A",
"tread":"\u76F8",
"co-ordinate":"\u594F",
"twist":"\u8D70",
"bend":"\u4E89",
"billion":"\u65E9",
"shine":"\u7A81",
"art":"\u5F0B",
"park":"\u6817",
"plant":"\u62C7",
"melt":"\u3856",
"show":"\u3857",
"congress":"\u384E",
"disgust":"\u3858",
"sacrifice":"\u3859",
"water":"\u385A",
"tarnish":"\u36B5",
"lazy":"\u385B",
"into":"\u385C",
"press":"\u385D",
"sound":"\u385E",
"mistake":"\u385F",
"yellow":"\u3860",
"read":"\u3861",
"flesh":"\u3862",
"touch":"\u948A",
"upon":"\u3863",
"weapon":"\u3864",
"channel":"\u3865",
"lean":"\u35FE",
"lay":"\u35FF",
"funnel":"\u3602",
"shine":"\u3604",
"serve":"\u3603",
"grow":"\u35F9",
"maiden":"\u3866",
"ceremony":"\u3867",
"drink":"\u35FC",
"encircle":"\u3600",
"speak":"\u3605",
"split":"\u3606",
"sprout":"\u3607",
"meal":"\u3608",
"tie":"\u360A",
"turn":"\u360B",
"fix":"\u35FB",
"buy":"\u35F7",
"without":"\u35F6",
"cultivate":"\u35F8",
"half":"\u39E7",
"known":"\u35F5",
"drain":"\u35FA",
"wear":"\u360C",
"thread":"\u3609",
"be":"\u35F4",
"against":"\u3868",
"roll":"\u3869",
"snatch":"\u386A",
"lie":"\u386B",
"able":"\u386C",
"wrap":"\u386D",
"grow":"\u386E",
"leak":"\u386F",
"stuff":"\u3870",
"sprinkle":"\u3871",
"mention":"\u3872",
"reality":"\u3873",
"family":"\u3874",
"centipede":"\u3875",
"cobweb":"\u3876",
"wine":"\u3877",
"dirt":"\u3878",
"plot":"\u3879",
"prophecy":"\u387A",
"branch":"\u387B",
"sacred":"\u387C",
"abomination":"\u387D",
"household":"\u387E",
"eaves":"\u387F",
"befriend":"\u36F2",
"advice":"\u3880",
"foam":"\u3881",
"bubbles":"\u3882",
"antelope":"\u3883",
"melon":"\u3884",
"manatee":"\u3885",
"sickness":"\u3886",
"fish":"\u3887",
"sap":"\u3888",
"season":"\u3889",
"gear":"\u388A",
"twine":"\u388B",
"torrent":"\u388C",
"stool":"\u36C1",
"neck":"\u388D",
"spirit":"\u388E",
"whale":"\u388F",
"tilapia":"\u3890",
"galilaea":"\u3891",
"mate":"\u36C0",
"kind":"\u36BF",
"storm":"\u36BE",
"time":"\u36BC",
"trap":"\u3892",
"hurt":"\u3893",
"wrap":"\u3894",
"between":"\u3895",
"move":"\u3896",
"tie":"\u3897",
"male":"\u3898",
"crocodile":"\u36FC",
"massage":"\u3899",
"reconsider":"\u389A",
"blame":"\u389B",
"rust":"\u389C",
"abort":"\u389D",
"crack":"\u389E",
"faint":"\u389F",
"fit":"\u38A0",
"name":"\u38A1",
"peel":"\u38A2",
"play":"\u38A3",
"soak":"\u38A4",
"stop":"\u38A5",
"chant":"\u38A6",
"lose":"\u38A7",
"bar":"\u36A4",
"pivot":"\u36A5",
"yield":"\u36A6",
"they":"\u36A3",
"piece":"\u38A8",
"flatulence":"\u38A9",
"squeeze":"\u38AA",
"creation":"\u38AB",
"ambush":"\u38AC",
"section":"\u38AD",
"appearing":"\u38AE",
"flip":"\u38AF",
"break":"\u38B0",
"name":"\u38B1",
"shock":"\u38B2",
"speak":"\u38B3",
"split":"\u38B4",
"carve":"\u38B5",
"tick":"\u38B6",
"curse":"\u38B7",
"plant":"\u38B8",
"differentiate":"\u38B9",
"fruit":"\u36FD",
"wine":"\u38BA",
"soup":"\u38BB",
"hoof":"\u38BC",
"grove":"\u38BD",
"intoxicate":"\u38BE",
"blow":"\u38BF",
"build":"\u38C0",
"dig":"\u38C1",
"shine":"\u38C2",
"coat":"\u38C3",
"yawn":"\u38C4",
"mouth":"\u38C5",
"past":"\u36A1",
"passive":"\u36A2",
"mancala":"\u3681",
"game":"\u38C6",
"seize":"\u38C7",
"storage":"\u3682",
"udara":"\u38C8",
"burst":"\u38C9",
"grapple":"\u38CA",
"burp":"\u38CB",
"mate":"\u3601",
"de-husk":"\u35FD",
"wash":"\u38CC",
"cloister":"\u38CD",
"unit":"\u38CE",
"balls":"\u38CF",
"house":"\u38D0",
"answer":"\u38D1",
"excited":"\u38D2",
"dissolve":"\u38D3",
"filter":"\u38D4",
"criticism":"\u38D5",
"cupping":"\u38D6",
"shell":"\u36FE",
"wine":"\u38D7",
"seize":"\u38D8",
"current":"\u38D9",
"plant":"\u38DA",
"claim":"\u38DB",
"wager":"\u38DC",
"miss":"\u38DD",
"sneeze":"\u38DE",
"confer":"\u38DF",
"mad":"\u38E0",
"assault":"\u38E1",
"sprinkle":"\u38E2",
"bleed":"\u38E3",
"burn":"\u38E6",
"limp":"\u38E7",
"distort":"\u38E8",
"wear":"\u38E9",
"stab":"\u3665",
"boil":"\u38EA",
"poke":"\u38EB",
"sharpen":"\u38EC",
"grow":"\u38ED",
"precede":"\u38EE",
"meat":"\u38EF",
"spike":"\u38F0",
"tusk":"\u38F1",
"head":"\u38F2",
"snake":"\u38F3",
"incense":"\u38F4",
"shrew":"\u38F5",
"price":"\u38F6",
"graveyard":"\u38F7",
"plant":"\u38F8",
"food":"\u38F9",
"in":"\u38FA",
"off":"\u38FB",
"simmer":"\u38FC",
"foam":"\u38FD",
"mention":"\u5F3A",
"relations":"\u8FBE",
"arrange":"\u38FF",
"cause":"\u3900",
"end":"\u3901",
"against":"\u3902",
"compare":"\u3903",
"emphasis":"\u37E8",
"copulate":"\u3904",
"protest":"\u3905",
"pain":"\u3906",
"egret":"\u3907",
"through":"\u3908",
"redeem":"\u3909",
"overtake":"\u390A",
"water":"\u390B",
"extinct":"\u390C",
"water":"\u390D",
"drum":"\u390E",
"tattoo":"\u3664",
"uja":"\u390F",
"restrain":"\u3910",
"out":"\u3911",
"foolishly":"\u3912",
"just":"\u3913",
"full":"\u3914",
"out":"\u3915",
"divide":"\u3916",
"stiffen":"\u3917",
"negative":"\u3918",
"will":"\u3919",
"when":"\u391A",
"scratch":"\u391B",
"bottle":"\u391C",
"than":"\u391D",
"kneel":"\u391E",
"carry":"\u391F",
"society":"\u3920",
"shelf":"\u3855",
"measles":"\u3854",
"foliage":"\u3921",
"ant":"\u3922",
"animal":"\u3923",
"Selaginella":"\u3924",
"yam":"\u3925",
"stump":"\u3926",
"baldness":"\u3927",
"knot":"\u3928",
"teeth":"\u3929",
"scorpion":"\u367C",
"africana":"\u3853",
"tubers":"\u392A",
"quarrel":"\u392B",
"sorcery":"\u392C",
"guess":"\u392D",
"dredge":"\u392E",
"scarce":"\u392F",
"attract":"\u3930",
"hand":"\u3931",
"cut":"\u3932",
"last":"\u3933",
"steer":"\u3934",
"smear":"\u3935",
"slander":"\u3936",
"mourn":"\u3937",
"beetle":"\u3938",
"baboon":"\u36B4",
"monkey":"\u3939",
"ram":"\u393A",
"infect":"\u393B",
"up":"\u393C",
"since":"\u393D",
"clone":"\u36AF",
"surpass":"\u393E",
"bird":"\u367F",
"token":"\u3680",
"title":"\u372D",
"wing":"\u367E",
"chick":"\u36A7",
"breadth":"\u5B10",
"tree":"\u36A8",
"rope":"\u36A9",
"gale":"\u36A0",
"panther":"\u36AA",
"play":"\u371E",
"money":"\u36AB",
"cup":"\u393F",
"plant":"\u3940",
"lion":"\u3941",
"adze":"\u3942",
"hat":"\u36F0",
"firm":"\u3943",
"eye":"\u3944",
"seatedness":"\u3945",
"tunnel":"\u3946",
"fence":"\u3947",
"stile":"\u3948",
"pox":"\u3949",
"bird":"\u394A",
"sample":"\u394B",
"framework":"\u3658",
"what":"\u365A",
"spoil":"\u365D",
"simmer":"\u365F",
"excess":"\u365E",
"scratch":"\u3661",
"area":"\u3662",
"tripetal":"\u394C",
"row":"\u3660",
"generation":"\u365B",
"dove":"\u3659",
"droppings":"\u3851",
"type":"\u3852",
"easier":"\u394E",
"loan":"\u394F",
"squirrel":"\u3950",
"rope":"\u3951",
"fund":"\u3952",
"cattle":"\u3953",
"ground":"\u3954",
"schedule":"\u3955",
"pillar":"\u3956",
"shard":"\u3957",
"straight":"\u3958",
"basket":"\u3959",
"river":"\u395A",
"bellows":"\u395B",
"gizzard":"\u395C",
"drum":"\u395D",
"abstain":"\u395E",
"starve":"\u395F",
"purchase":"\u3960",
"fine":"\u3961",
"catamaran":"\u3962",
"wasp":"\u3963",
"melanoleuca":"\u3964",
"plant":"\u3965",
"civet":"\u3966",
"glaucescens":"\u3967",
"dress":"\u3968",
"wear":"\u36B8",
"startle":"\u36B9",
"understand":"\u36ED",
"crown":"\u36EF",
"persevere":"\u3712",
"perforate":"\u370F",
"wear":"\u3969",
"shave":"\u396A",
"smooth":"\u396B",
"in":"\u396C",
"festival":"\u396D",
"flood":"\u36AC",
"eggplant":"\u396E",
"kola":"\u396F",
"eucalyptus":"\u3663",
"paint":"\u3970",
"phallus":"\u3971",
"thickness":"\u3972",
"antelope":"\u3973",
"ulcer":"\u36AD",
"chaff":"\u36AE",
"again":"\u36B0",
"tree":"\u36B1",
"legume":"\u36B2",
"beetle":"\u36B3",
"influence":"\u3974",
"cloth":"\u3975",
"eczema":"\u3976",
"woods":"\u3977",
"shade":"\u3978",
"loom":"\u3979",
"grass":"\u397A",
"line":"\u397B",
"ants":"\u397C",
"insect":"\u397D",
"worm":"\u397E",
"cymbal":"\u397F",
"widow":"\u3980",
"parts":"\u3981",
"crocodile":"\u3982",
"snake":"\u3983",
"person":"\u3984",
"pad":"\u3985",
"harvest":"\u3986",
"antidote":"\u3987",
"nitrate":"\u366C",
"fronds":"\u366B",
"caterpillar":"\u384D",
"pneumonia":"\u3988",
"branch":"\u3989",
"imprisonment":"\u398A",
"overlook":"\u3720",
"exclaim":"\u398B",
"heal":"\u398C",
"ferment":"\u398D",
"hide":"\u398E",
"out":"\u398F",
"hurt":"\u3990",
"fold":"\u3991",
"reckon":"\u3992",
"about":"\u3993",
"space":"\u3994",
"whetstone":"\u3995",
"bitterleaf":"\u3996",
"lead":"\u3997",
"carve":"\u3998",
"bypass":"\u365C",
"nakedness":"\u3999",
"cholera":"\u399A",
"monster":"\u399B",
"haemorrhage":"\u399C",
"axe":"\u399D",
"conjuring":"\u399E",
"plank":"\u399F",
"snot":"\u36BA",
"vindicate":"\u36BB",
"worship":"\u39A0",
"deny":"\u39A1",
"bend":"\u39A2",
"flesh":"\u39A3",
"bame":"\u39A4",
"embrace":"\u39A5",
"coals":"\u39A6",
"add":"\u366F",
"avenge":"\u3670",
"lure":"\u366E",
"flexible":"\u39A7",
"limbs":"\u39A8",
"scrape":"\u39A9",
"soft":"\u39AA",
"blend":"\u39AB",
"dam":"\u39AC",
"comb":"\u39AD",
"consider":"\u39AE",
"rain":"\u39AF",
"rear":"\u39B0",
"send":"\u39B1",
"sound":"\u39B2",
"tree":"\u3671",
"show":"\u39B3",
"trample":"\u39B4",
"scrub":"\u39B5",
"report":"\u39B6",
"move":"\u39B7",
"title":"\u39B8",
"replace":"\u39B9",
"cut":"\u39BA",
"crticise":"\u39BB",
"grunt":"\u39BC",
"skin":"\u39BD",
"present":"\u39BE",
"trim":"\u39BF",
"light":"\u39C0",
"up":"\u39C1",
"partially":"\u39C2",
"thorougly":"\u39C3",
"gun":"\u39C4",
"desire":"\u39C5",
"display":"\u39C6",
"decide":"\u39C7",
"happy":"\u39C8",
"bedbug":"\u39C9",
"room":"\u39CA",
"follow":"\u39CB",
"plant":"\u39CC",
"out":"\u39CD",
"precede":"\u39CE",
"sit":"\u39CF",
"against":"\u39D0",
"soothe":"\u39D1",
"swear":"\u39D2",
"vomit":"\u39D3",
"mood":"\u39D4",
"advise":"\u39D5",
"stay":"\u39D6",
"pardon":"\u39D7",
"reveal":"\u39D8",
"transplant":"\u39D9",
"dibia":"\u39DA",
"keep":"\u39DB",
"stop":"\u39DC",
"against":"\u39DD",
"win":"\u369F",
"creature":"\u39DE",
"oyster":"\u39DF",
"plain":"\u39E0",
"dry":"\u39E1",
"poke":"\u39E2",
"covet":"\u39E3",
"sneak":"\u39E4",
"penetrate":"\u39E6",
"nose":"\u39E8",
"report":"\u39E9",
"sift":"\u39EA",
"vogue":"\u39EB",
"seethe":"\u39EC",
"build":"\u39ED",
"fatness":"\u39EE",
"frankincense":"\u39EF",
"tree":"\u39F0",
"play":"\u39F1",
"request":"\u39F2",
"spirit":"\u39F3",
"buy":"\u39F4",
"tether":"\u39F5",
"stab":"\u39F6",
"jump":"\u39F7",
"rinse":"\u39F8",
"negative":"\u39F9",
"nostalgic":"\u39FA",
"gain":"\u369E",
"wood":"\u39FB",
"cone":"\u39FC",
"market":"\u39FD",
"sunset":"\u39FE",
"follow":"\u39FF",
"hang":"\u3A00",
"molars":"\u3A01",
"potto":"\u3A02",
"guards":"\u367B",
"spear":"\u3A03",
"statue":"\u3A04",
"play":"\u3A05",
"damp":"\u3A06",
"non-initiate":"\u3A07",
"tree":"\u3A08",
"crowd":"\u3A09",
"friendship":"\u3A0A",
"bat":"\u3666",
"oracle":"\u367D",
"tree":"\u3A0B",
"tree":"\u3A0C",
"lies":"\u3A0D",
"smallpox":"\u3A0E",
"rope":"\u3A0F",
"mound":"\u3A10",
"scratch":"\u3A11",
"cause":"\u3A12",
"mate":"\u3684",
"share":"\u6218",
"pumpkin":"\u3A13",
"gum":"\u3A14",
"yet":"\u6743",
"piece":"\u3A15",
"wool":"\u3A16",
"insinuation":"\u36BD",
"hen":"\u3A17",
"molar":"\u3A18",
"teeth":"\u3A19",
"cord":"\u3A1A",
"knot":"\u3A1B",
"hair":"\u3A1C",
"cat":"\u3A1D",
"dam":"\u3A1E",
"hole":"\u3A1F",
"squirrel":"\u3A20",
"egg":"\u3A21",
"kite":"\u3A22",
"heron":"\u3A23",
"navel":"\u3A24",
"flour":"\u3A25",
"utazi":"\u3A26",
"current":"\u3A27",
"extremity":"\u3A28",
"profit":"\u3A29",
}