import KeyboardLayouts from '../Components/KeyboardLayouts/layouts.js'
import keyboardMap from '../Components/KeyboardLayouts/layouts.js'

let scripts = [
    {
        label:"N'ko", 
        value:"Nko",
        kayboardLayout: keyboardMap['Nko']
    }, 
    {
        label:"Adlam", 
        value:"Adlam",
        kayboardLayout: keyboardMap['Adlam']
    },
    {
        label:"Gʋlse", 
        value:"Gʋlse",
        kayboardLayout: keyboardMap['Gʋlse']
    },
    {
        label:"Nsibidi", 
        value:"Nsibidi",
        kayboardLayout: keyboardMap['Nsibidi']
    },
    {
        label:"Vai", 
        value:"Vai",
        kayboardLayout: keyboardMap['Vai']
    },
    {
        label:"Bamum", 
        value:"Bamum",
        kayboardLayout: keyboardMap['Bamum']
    },
    {
        label:"Tifinagh", 
        value:"Tifinagh",
        kayboardLayout: keyboardMap['Tifinagh']
    },
    {
        label:"Bete", 
        value:"Bete",
        kayboardLayout: keyboardMap['Bete']
    },
    {
        label:"isiBheqe", 
        value:"isiBheqe",
        kayboardLayout: keyboardMap['isiBheqe']
        
    },
    {
        label:"Osmanya", 
        value:"Osmanya",
        kayboardLayout: keyboardMap['Osmanya']
    },
    {
        label:"Garay", 
        value:"Garay",
        kayboardLayout: keyboardMap['Garay']
    },
    {
        label:"Taxi", 
        value:"Taxi",
        kayboardLayout: keyboardMap['Taxi']
    },


]

export default scripts;